import React, { lazy } from 'react';
import {
  SettingOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  PayCircleOutlined,
  FunnelPlotOutlined,
  UserOutlined,
  CommentOutlined
  
} from '@ant-design/icons';

/**
 * 路由懒加载
 * @param {String} filename 文件路径
 */
const lazyRouter = (filename: string) => {
  return lazy(() => import(`../pages/${filename}`))
}
export const RouteMap = [
  { path: '/', name: 'home', title: '主页', component: lazyRouter('Home/Index'), isMenu: false},
  { path: '/customer', name: 'customer', title: '客户列表', icon: <PayCircleOutlined />, component: lazyRouter('customer/Index'), isMenu: true,},
  { path: '/visit', name: 'visit', title: '回访列表', icon: <PayCircleOutlined />, component: lazyRouter('visit/Index'), isMenu: true,},
  { path: '/company', name: 'company', title: '公司管理', icon: <PayCircleOutlined />, component: lazyRouter('company/Index'), isMenu: true,},
  { path: '/configuration', name: 'configuration', title: '配置管理', icon: <PayCircleOutlined />, component: lazyRouter('configuration/Index'), isMenu: true,},
  { path: '/fallPage', name: 'fallPage', title: '落地页管理', icon: <PayCircleOutlined />, component: lazyRouter('fallPage/Index'), isMenu: true,},

  { path: '', name: 'permission', title: '权限管理', icon: <FunnelPlotOutlined />, isMenu: true,
    children: [
      {path: '/account', name: 'account', title: '账号管理', component: lazyRouter('authority/Account'), isMenu: true},
      {path: '/role', name: 'role', title: '角色管理', component: lazyRouter('authority/Role'), isMenu: true},
    ]
  },
]

let routeList: any[] = []
let neatenRouteMap = (list: any[]) => {
  list.forEach((item: any) => {
    if (item.children && item.children.length) {
      neatenRouteMap(item.children)
    } else {
      routeList.push(item)
    }
  })
}
neatenRouteMap(RouteMap)

export const RouteList = routeList
