import { fetch } from './axios'

export const login: any = (data: any) => { // 登录
  return fetch(`auth/login`, data, `post`)
}

export const getChannel: any = (data: any) => { // 渠道列表
  return fetch(`channel`, data)
}
export const screen_company: any = (data: any) => { // 渠道列表
  return fetch(`screen_company`, data)
}
export const screen_page: any = (data: any) => { // 来源落地页
  return fetch(`screen_page`, data)
}
export const getCustomer: any = (data: any) => { // 客户类列表
  return fetch(`customer`, data)
}
export const get_visit: any = (data: any) => { // 回访列表
  return fetch(`visit`, data)
}
export const save_visit: any = (data: any) => { // 添加回访记录
  return fetch(`save_visit`, data, 'POST')
}
export const visit: any = (data: any) => { // 单条回访记录
  return fetch(`visit/${data}`, {})
}
export const customerDel: any = (data: any) => { // 删除客户
  return fetch(`customer/${data}`, {}, 'DELETE')
}
export const get_landing: any = (data: any) => { // 落地页列表
  return fetch(`landing`, data)
}
export const landing_channel: any = (data: any) => { // 运营机构
  return fetch(`landing/channel/${data}`, {})
}
export const add_landing: any = (data: any) => { // 新增落地页
  return fetch(`landing`, data, 'POST')
}
export const put_landing: any = (data: any) => { // 编辑落地页
  return fetch(`landing/${data.id}`, data, 'PUT')
}
export const landingDel: any = (data: any) => { // 删除落地页
  return fetch(`landing/${data}`, {}, 'DELETE')
}
export const get_company: any = (data: any) => { // 公司列表
  return fetch(`company`, data)
}
export const add_company: any = (data: any) => { // 新增公司
  return fetch(`company`, data, 'POST')
}
export const put_company: any = (data: any) => { // 编辑公司
  return fetch(`company/${data.id}`, data, 'PUT')
}
export const landing_page: any = (data: any) => { // 获取落地页权限列表
  return fetch(`landing_page`, data)
}
export const save_landing_page: any = (data: any) => { // 保存落地页归属
  return fetch(`save_landing_page`, data, 'POST')
}
export const getOperation: any = (data: any) => { // 运营机构列表
  return fetch(`operation`, data)
}
export const add_channel: any = (data: any) => { // 新增渠道
  return fetch(`channel`, data, 'POST')
}
export const put_channel: any = (data: any) => { // 修改渠道
  return fetch(`channel/${data.id}`, data, 'PUT')
}
export const del_channel: any = (data: any) => { // 删除渠道
  return fetch(`channel/${data.id}`, data, 'DELETE')
}
export const add_operation: any = (data: any) => { // 新增机构
  return fetch(`operation`, data, 'POST')
}
export const put_operation: any = (data: any) => { // 修改机构
  return fetch(`operation/${data.id}`, data, 'PUT')
}
export const del_operation: any = (data: any) => { // 删除机构
  return fetch(`operation/${data.id}`, data, 'DELETE')
}

export const logout: any = (data: any) => { // 注销登录
  return fetch(`logout`, data, 'POST')
}
export const passwordModify: any = (data: any) => { // 修改密码
  return fetch(`modify/pwd`, data, 'POST')
}
export const upload: any = (data: any) => { // 上传图片
  return fetch(`image/upload`, data, 'post', true)
}





export const province: any = (data: any) => { // 获取省份
  return fetch(`code?type=province`, data)
}
export const city: any = (data: any) => { // 获取城市
  return fetch(`code?type=city`, data)
}
// export const getProduct: any = (data: any) => { // 查询产品列表/单个产品
//   return fetch(`product`, data)
// }
// export const addProduct: any = (data: any) => { // 添加产品
//   return fetch(`product`, data, `post`)
// }
// export const putProduct: any = (data: any) => { // 保存修改产品
//   return fetch(`product/${data.id}`, data, `PUT`)
// }
// export const productDetail: any = (data: any) => { // 查询产品详情
//   return fetch(`product/${data.id}`, data)
// }
// export const deleteProduct: any = (data: any) => { // 删除产品
//   return fetch(`product/${data.id}`, data, `DELETE`)
// }
export const queryAgent: any = (data: any) => { // 用户列表
  return fetch(`agent`, data)
}
export const queryOrder: any = (data: any) => { // 订单列表
  return fetch(`apply`, data)
}
export const finishOrder: any = (data: any) => { // 结算订单
  return fetch(`apply/${data.id}`, data, `PUT`)
}
export const failedOrder: any = (data: any) => { // 结算订单不通过
  return fetch(`apply/failed/${data.id}`, data, `POST`)
}

export const queryCash: any = (data: any) => { // 佣金列表
  return fetch(`commission`, data)
}
export const finishCash: any = (data: any) => { // 佣金结算
  return fetch(`commission/${data.id}`, data, `PUT`)
}
export const failedCash: any = (data: any) => { // 佣金结算失败
  return fetch(`commission/failed/${data.id}`, data, `PUT`)
}

export const queryBanner: any = (data: any) => { // Banner列表
  return fetch(`slide`, data)
}
export const BannerDetail: any = (data: any) => { // 查询产品列表/单个产品
  return fetch(`slide/${data.id}`, data)
}
export const addBanner: any = (data: any) => { // 创建海报
  return fetch(`slide`, data, 'post')
}
export const putBanner: any = (data: any) => { // 保存修改产品
  return fetch(`slide/${data.id}`, data, `PUT`)
}
export const delBanner: any = (data: any) => { // 删除海报
  return fetch(`slide/${data.id}`, data, 'DELETE')
}





export const getRole: any = (data: any) => { // 获取角色列表
  return fetch(`role`, data, 'get')
}
export const getRoleDetail: any = (data: any) => { // 获取角色详情
  return fetch(`role/${data.id}`, data, 'get')
}
export const addRole: any = (data: any) => { // 添加角色
  return fetch(`role`, data, 'post')
}
export const editRole: any = (data: any) => { // 编辑角色
  return fetch(`role/${data.id}`, data, 'put')
}
export const deleteRole: any = (data: any) => { // 删除角色
  return fetch(`role/${data.id}`, data, 'DELETE')
}
export const getPermission: any = (data: any) => { // 获取权限列表
  return fetch(`permission`, data, 'get')
}
export const getAdmin: any = (data: any) => { // 获取账号列表
  return fetch(`admin`, data, 'get')
}
export const addAdmin: any = (data: any) => { // 添加账号
  return fetch(`admin`, data, 'post')
}
export const getAdminDetail: any = (data: any) => { // 获取账号详情
  return fetch(`admin/${data.id}`, data, 'get')
}
export const editAdmin: any = (data: any) => { // 编辑账号
  return fetch(`admin/${data.id}`, data, 'put')
}
export const deleteAdmin: any = (data: any) => { // 删除账号
  return fetch(`admin/${data.id}`, data, 'DELETE')
}
export const passwordReset: any = (data: any) => { // 重置密码
  return fetch(`admin/pwd_reset/${data.id}`, data, 'put')
}
export const status: any = (data: any) => { // 冻结/解冻管理员
  return fetch(`admin/status/${data.id}`, data, 'put')
}
export const getConfig: any = (data: any) => { // 获取配置列表
  return fetch(`config`, data)
}
export const addConfig: any = (data: any) => { // 新增/修改 配置
  return fetch(`config`, data, 'POST')
}
export const changeConfigStatus: any = (data: any) => { // 编辑配置状态
  return fetch(`config/${data.id}`, data, 'PUT')
}
export const configDetail: any = (data: any) => { // 配置详情
  return fetch(`config/detail`, data)
}
export const delConfig: any = (data: any) => { // 删除配置
  return fetch(`config/${data.id}`, data, 'DELETE')
}


export const getTag: any = (data: any) => { // 标签列表
  return fetch(`tag`, data)
}
export const addTag: any = (data: any) => { // 新增/编辑 标签
  return fetch(`tag`, data,  'POST')
}
export const changeTagStatus: any = (data: any) => { // 修改标签状态
  return fetch(`tag/${data.id}`, data, 'PUT')
}
export const tagDetail: any = (data: any) => { // 标签详情
  return fetch(`tag/${data.id}`, data)
}
export const delTag: any = (data: any) => { // 删除标签
  return fetch(`tag/${data.id}`, data, 'DELETE')
}

export const getArticle: any = (data: any) => { // 文章列表
  return fetch(`article`, data)
}
export const addArticle: any = (data: any) => { // 新增/编辑 标签
  return fetch(`article`, data,  'POST')
}
export const changeArticleStatus: any = (data: any) => { // 修改文章状态
  return fetch(`article/${data.id}`, data, 'PUT')
}
export const articleDetail: any = (data: any) => { // 文章详情
  return fetch(`article/${data.id}`, data)
}
export const delArticle: any = (data: any) => { // 删除文章
  return fetch(`article/${data.id}`, data, 'DELETE')
}

export const getCategory  : any = (data: any) => { // 分类列表
  return fetch(`category`, data)
}
export const addCategory : any = (data: any) => { // 新增/编辑 分类
  return fetch(`category`, data,  'POST')
}
export const delCategory: any = (data: any) => { // 删除分类
  return fetch(`category/${data.id}`, data, 'DELETE')
}

export const getAgent : any = (data: any) => { // 顾问列表
  return fetch(`agent`, data)
}
export const addAgent: any = (data: any) => { // 新增/编辑 顾问
  return fetch(`agent`, data,  'POST')
}
export const changeAgentStatus: any = (data: any) => { // 修改顾问状态
  return fetch(`agent/${data.id}`, data, 'PUT')
}
export const agentDetail: any = (data: any) => { // 顾问详情
  return fetch(`agent/${data.id}`, data)
}
export const delAgent: any = (data: any) => { // 删除顾问
  return fetch(`agent/${data.id}`, data, 'DELETE')
}

export const getProduct: any = (data: any) => { // 产品列表
  return fetch(`product`, data)
}
export const addProduct: any = (data: any) => { // 新增/编辑 产品
  return fetch(`product`, data,  'POST')
}
export const changeProductStatus: any = (data: any) => { // 修改产品状态
  return fetch(`product/${data.id}`, data, 'PUT')
}
export const productDetail: any = (data: any) => { // 产品详情
  return fetch(`product/${data.id}`, data)
}
export const delProduct: any = (data: any) => { // 删除产品
  return fetch(`product/${data.id}`, data, 'DELETE')
}

export const getOrder: any = (data: any) => { // 赔付列表
  return fetch(`order`, data)
}
export const changeOrder: any = (data: any) => { // 处理赔付
  return fetch(`order/${data.id}`, data, 'PUT')
}

export const getUser: any = (data: any) => { // 用户列表 
  return fetch(`user`, data)
}
export const addUser: any = (data: any) => { // 添加客户标记 
  return fetch(`user`, data, 'POST')
}

export const getTop: any = (data: any) => { // 列表数据
  return fetch(`top`, data)
}
export const addTop: any = (data: any) => { // 新增/编辑 榜单
  return fetch(`top`, data, 'POST')
}
export const topDetail: any = (data: any) => { // 榜单详情
  return fetch(`top/${data.id}`, data)
}
export const delTop: any = (data: any) => { // 删除榜单
  return fetch(`top/${data.id}`, data, 'DELETE')
}
export const changeTopStatus: any = (data: any) => { // 修改榜单状态
  return fetch(`top/${data.id}`, data, 'PUT')
}

export const getMessageUser: any = (data: any) => { // 获取聊天列表
  return fetch(`message`, data)
}
export const addMessage : any = (data: any) => { // 发送消息
  return fetch(`message`, data,  'POST')
}
export const getMessage: any = (data: any) => { // 历史消息详情
  return fetch(`message/${data.id}`, data)
}
export const getNewMessage: any = (data: any) => { // 获取最新消息
  return fetch(`new/message`, data)
}